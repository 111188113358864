import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router, CanActivate } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

//Services
import { ErrorhandlerService } from './errorhandler.service';


//Environment
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService implements CanActivate {
	private apiUrl = environment.apiUrl; 
	public profile_id=null;//'49';
	public profileObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public tokenObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public roleObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	adminView=false;
	edit_addr=false;
	edit_eml=false;
	edit_pw=false;
	edit_state=null;
	edit_submitted=false;
	eml_in_use=false
	edit_admin=false;
	admin_stage='user_info';
	current_user_detail=null;

  constructor(	 
	 private http: HttpClient,			  
	 private cookie: CookieService,
	 private location: Location,
	 private router: Router,
	 private errorhandlerService: ErrorhandlerService) {
	 	 this.initService();	
	 }
	loadProfile(id){
		console.log('loading from id', id);
		//this.cookie.delete('profile');
		//this.cookie.set('profile', id);
		localStorage.removeItem('profile');
		localStorage.setItem('profile', id);
		if(id>0){
					console.log('generateing from loadprofile');
			this.generateProfile(id).subscribe((response) => {
				console.log('body[0]',response.body[0]);
				var temp = this.processProfile(response.body[0]); 
				//localStorage.setItem('role', temp.role);
				this.roleObj.next(temp.role);
				if(temp.role=="admin"){
					this.adminView=true;
				}else{
					this.adminView=false;
				}
				this.profileObj.next(temp);
				//console.log('obj', this.profileObj.getValue());
				//this.AS.updateAssets(id);
			 });
		}
	}
	loadProfilefromStorage(){
		console.log('loading from storage');
		this.profile_id = localStorage.getItem('profile');
		var token=localStorage.getItem('jwt');
		this.loadProfile(this.profile_id);
		this.tokenObj.next(token);
		if(this.profileObj.getValue()==[]){
			return false;
		}else{
			return true;
		}
	}
	generateProfile(id): Observable<any> {
		//this needs to check to load from cookie vs load from profile
		return this.http.get<any[]>(this.apiUrl + 'profile/'+id, { observe: 'response' } )
      .pipe(
        catchError(this.errorhandlerService.handleError())
      );
				console.log('generated profile');
	}
	updateProfile(id, json_data){
		return this.http.put<any[]>(this.apiUrl + 'profile/'+ id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	processProfile(data){
		return data;
	}
	getProfile(){
		return this.profileObj;
	}
	getToken(){
		return this.tokenObj;
	}
	getRole(){
		return this.roleObj;
	}
	logout(){
		//this.cookie.delete('profile');
		//this.cookie.delete('jwt');
		localStorage.removeItem('profile');
		localStorage.removeItem('jwt');
		//localStorage.removeItem('role');
		this.tokenObj.next(null);
		this.roleObj.next(null);
		this.profileObj.next(null);
		this.router.navigate(['/home']);
	  }
	
	postLogin(json_data):Observable<any>{
		return this.http.post<any[]>(this.apiUrl + 'login', json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	checkRegister(json_data):Observable<any>{
		return this.http.post<any[]>(this.apiUrl + 'registered', json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	loginToken( token){
		localStorage.removeItem('jwt');
		localStorage.setItem('jwt',token);
		//this.cookie.delete('jwt');
		//this.cookie.set('jwt',token);
		this.tokenObj.next(token);
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + token
			})
		};
	}
	passwordReminder(json_data):Observable<any>{
		return this.http.post<any[]>(this.apiUrl + 'reminder', json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	registerUser(json_data):Observable<any>{
		return this.http.post<any[]>(this.apiUrl + 'register', json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	initService(){
		var jwt = localStorage.getItem('jwt');
		//var jwt = this.cookie.get('jwt');
		console.log('1init',jwt);
		if(!jwt){
			//this.router.navigate(['/home']);
		}else{
			var attempt = this.loadProfilefromStorage();
			if(!attempt){
				this.router.navigate(['/home']);
				console.log(attempt, this.profileObj)
			}else{
				console.log(attempt, this.profileObj)
			}
		}
	}
	canActivate(): boolean {
		var jwt = localStorage.getItem('jwt');
		///var jwt = this.cookie.get('jwt');
		console.log('1init',jwt);
		if(!jwt){
			this.router.navigate(['login']);
		  	return false;
		}else{
			var attempt = this.loadProfilefromStorage();
			if(!attempt){
				this.router.navigate(['/login']);
				return false;
			}else{
				return true;
			}
		}
	}
	closeModals(){
		this.edit_addr=false;
		this.edit_eml=false;
		this.edit_pw=false;
		this.edit_state=null;
		this.edit_submitted=false;
		this.edit_admin=false;
		this.admin_stage='user_info'
		this.profile_id = localStorage.getItem('profile');
		this.loadProfile(this.profile_id);
	}
	editProfile(el){
		this.edit_state=el;
		this['edit_'+el]=true;
	}
	
	checkEmail(prf){
		var json_data = [];
		var temp
		json_data.push({email: prf.email});
		temp=this.checkRegister(json_data);	
		return temp;
	}	
}
 
	



	






