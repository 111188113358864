<div class="hero">
	<div class="shell">
		<div class="hero__inner">
			<h1>Welcome to the Hallmark Media Partner Site</h1>
			

			<h3>Access programming resources and toolkits that unlock feel good moments to Hallmark viewers everywhere.</h3>

			<a (click)="CS.loginBegin();" class="btn hero__btn" *ngIf="token?.length==0 || !token">Sign In/Register</a>

			<swiper class="swiper slider-cards"  [config]="config">
					<ng-template swiperSlide class="swiper-slide slider__slide"
						 *ngFor="let item of Content | statusFilter: 'network_id': '6' | statusFilter: 'page_feature': '0' | sortBy: 'asc' : 'order'" data-hash="item.content_id">
						<div class="card">
							<div class="card__inner">
								<div class="card__front">
									<figure class="card__background">
										<img src="{{item.artwork}}" alt="{{item.header}}" width="589" height="330">

									<!--	<a *ngIf="item.content_type!=='external'" routerLink="/library/{{item.reference}}/{{item.content_type}}" 
										   class="btn card__btn">{{item.cta? item.cta : 'View Assets'}}</a>
										<a *ngIf="item.content_type=='external'" href="{{item.reference}}" target="_blank" class="btn card__btn">{{item.cta? item.cta : 'Visit Site'}}</a>-->
									</figure><!-- /.card__background -->
								</div><!-- /.card__front -->

								<div class="card__back">
									<div class="card__entry">
										<h2 [innerHtml]="item.headline"></h2>

										<div class="card__info" *ngIf="item.subhead">
											<p [innerHtml]="item.subhead"></p>
										</div><!-- /.card__info -->

										<p [innerHtml]="item.copy"></p>
										<ng-container *ngIf="item.cta!=='none'">
											<a *ngIf="item.video=='1'" (click)="launchVid(item.reference);CS.trackActivity('video',item.content_id)" class="btn  btn--purple card__btn mr1">Play Video</a>
											<a *ngIf="item.content_type!=='external' && item.content_type!=='modal'" 
										   (click)="CS.followLink(item.content_type,item.reference, 'home', item.content_id)"
										   class="btn  btn--purple card__btn">{{item.cta? item.cta : 'View Assets'}}</a>
											<a *ngIf="item.content_type=='external'"
										   (click)="CS.followLink(item.content_type,item.reference, 'home', item.content_id)"
										   class="btn  btn--purple  card__btn">{{item.cta? item.cta : 'Visit Site'}}</a>
											<a *ngIf="item.content_type=='modal'" (click)="launchVid(item.reference);CS.trackActivity('video',item.content_id)" 
										   class="btn  btn--purple card__btn">{{item.cta? item.cta : 'View Assets'}}</a>
										</ng-container>

									</div><!-- /.card__entry -->
								</div><!-- /.card__back -->
							</div><!-- /.card__inner -->

							<h6>{{item.undertag}}</h6>
						</div><!-- /.card -->
					</ng-template><!-- /.swiper-slide slider__slide -->
			</swiper><!-- /.swiper--> 


		</div><!-- /.hero__inner -->
	</div><!-- /.shell -->
</div><!-- /.hero -->
<!--
<app-schedule></app-schedule>
-->
<section class="section-callout" >
	<div class="shell">
		<div class="callout">
			<h2>Contact Us</h2>

			<div class="callout__entry">
				<p>
					<a href="mailto:info@hallmarkmediahub.com" class="link">Contact us</a> directly to learn about customizable promotions and partnerships utilizing Hallmark's unique brand assets and premiums.
				</p>
				<ng-container *ngIf="token?.length==0 || !token">
<!--				<p>Check out our <a href="https://ik.imagekit.io/hallmedia/hallmark_tutorial_v5.mp4" target="_blank" class="link">video tutorial</a> for help setting up and navigating the Hallmark Media Hub. There's also a <a href="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/email/HallmarkMediaHub_WebsiteUserSteps.pdf" target="_blank" class="link" >guide</a> available for download.</p>-->
				<p class="disclaimer">Please note that access to this website is exclusively for our affiliate partners. If you have press inquiries, please visit our press site at <a href="https://press.hallmarkmedia.com" target="_blank">press.hallmarkmedia.com</a></p>
					</ng-container>
			</div><!-- /.callout__entry -->
		</div><!-- /.callout -->
	</div><!-- /.shell -->
</section><!-- /.section-callout -->

<app-login ></app-login>
<app-home-preview></app-home-preview>
