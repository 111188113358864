import { Component, OnInit, OnDestroy,ViewChild} from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperComponent } from "swiper/angular";
import SwiperCore, { SwiperOptions, Swiper, Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
	config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 31,
    navigation: true,
	loop: true,
    centeredSlides: true,
    initialSlide:1,
    hashNavigation: true,
    pagination: false,
    scrollbar: false,
    
    breakpoints: {
		767: {
		  slidesPerView: 'auto',
		  spaceBetween: 20,
		},
	}
	
  };
	private ngUnsubscribe = new Subject<void>();

loginState=null;
asset_id=null;
vidplayed=false;
Profile:any|[];
Content:any|[];
token:any|[];
  constructor(public PS: ProfileService, public AS: AssetsService, public CS:ContentManagerService,public route: ActivatedRoute, private router: Router) {  }

  ngOnInit(): void {
	  this.loginState=null;
	  console.log('mkstart1',localStorage.getItem("previewID"),this.CS.homePreviewOpen, this.CS.loginOpen);
	  //if its preview, capture the preview id to local
	  //if there is a local, don't reset it to null
	  this.route.paramMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
		  this.loginState=params.get('loginstate');
		   console.log('checkpreview1a',localStorage.getItem("previewID"))
		  if(params.get('asset')!==null){
			  localStorage.removeItem("previewID");
		  	  localStorage.setItem("previewID",params.get('asset'));
			   console.log('set preview2',params.get('asset'))
		  }
		  console.log('params'+this.loginState+'crit'+ params.get('asset'));
		  if((this.loginState=="preview" || !localStorage.getItem("previewID")===null) && this.loginState!=="logout"){
			  console.log('preview3',this.loginState,this.CS.loginState,localStorage.getItem("previewID") );
			  this.CS.homePreviewId=localStorage.getItem("previewID");
			  this.CS.homePreviewOpen=true;
			  this.CS.loginState="begin";
			  this.CS.loginOpen=false;
			  
		  }else if(this.loginState=="logout"){
			  localStorage.removeItem("previewID");
			  this.CS.homePreviewOpen=false;
			  this.PS.logout();
		  }else if(this.loginState=="login"){
			  localStorage.removeItem("previewID");
			  this.CS.homePreviewOpen=false;
			  this.CS.loginState="begin";
			  this.CS.loginOpen=true;
		  }else {
			  console.log('just home',localStorage.getItem("previewID"));
			  this.CS.homePreviewOpen=false;
			  this.CS.loginState="begin";
			  this.CS.loginOpen=false;
		  }
	  });
	 // this.loginState=this.route.snapshot.paramMap.get('loginstate');
	 // this.CS.homePreviewId=this.route.snapshot.paramMap.get('asset');
	 // console.log('state', this.loginState);
	 // console.log('preid', this.route.snapshot.paramMap.get('asset'));
	//  if(this.loginState=="reset"){
	//	  localStorage.removeItem("vid")
	//	  localStorage.setItem("vid","reset");
	//	  this.router.navigate(['home']);
	 // }
	 // if(localStorage.getItem("vid")=="played"){
	//	  this.CS.vidOpen=false;
	 // }else{
	//	  this.CS.vidOpen=true;
	 // }
	  
	 // this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Profile = data; console.log(this.Profile)});
	  this.PS.getToken().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.token = data; console.log('tookeninit', this.token)});
	  this.CS.getContent().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Content=data; console.log('content', this.Content);
	  });
	  
  }
	launchVid(id){
		this.CS.homePreviewId=id;
		this.CS.homePreviewOpen=true;
	}
   ngOnDestroy()  {
	this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
  }
}
